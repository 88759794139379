import React, { useState } from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

const OurMethodologies = () => {
  const [isRendered, setIsRendered] = useState(false)

  return (
    <section className="our-methodologies">
      <div className="container">
        <h2>Driven by data, simplified for you</h2>
        <div className="our-methodologies-stats">
          <div className="our-methodologies-stats-item">
            <VisibilitySensor partialVisibility={true} offset={{ bottom: 0 }}>
              {({ isVisible }) => {
                return (
                  <div className="home-counters">
                    {isVisible && !isRendered ? <CountUp end={64} /> : 64}
                  </div>
                )
              }}
            </VisibilitySensor>
            <p className="our-methodologies-stats-item-desc">
              Internationally accepted academic and government climate models
              aggregated
            </p>
          </div>
          <div className="our-methodologies-stats-item">
            <VisibilitySensor partialVisibility={true} offset={{ bottom: 0 }}>
              {({ isVisible }) => (
                <div className="home-counters">
                  {isVisible && !isRendered ? <CountUp end={170} /> : 170}
                  <span>M+</span>
                </div>
              )}
            </VisibilitySensor>
            <p className="our-methodologies-stats-item-desc">
              North American properties covered by our real estate risk
              platform.
            </p>
          </div>
          <div className="our-methodologies-stats-item">
            <VisibilitySensor partialVisibility={true} offset={{ bottom: 0 }}>
              {({ isVisible }) => (
                <div className="home-counters">
                  {isVisible && !isRendered ? (
                    <CountUp end={10} onEnd={() => setIsRendered(true)} />
                  ) : (
                    10
                  )}
                  <span>B+</span>
                </div>
              )}
            </VisibilitySensor>
            <p className="our-methodologies-stats-item-desc">
              Multiple climate scenarios and billions of risk data points used
              in our assessment and reporting algorithm.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurMethodologies
